import request from '@/api/request.js'

//查询表单详情
export function getFormDetail(templateId) {
    return request({
        url: '/admin/form/detail/' + templateId,
        method: 'get'
    })
}

//创建表单
export function createForm(data) {
    return request({
        url: '/admin/form',
        method: 'post',
        data: data
    })
}

// 更新表单详情
export function updateFormDetail(param) {
    return request({
        url: '/admin/form',
        method: 'put',
        data: param
    })
}

//移动表单
export function moveForm(templateId, groupId) {
    return request({
        url: '/admin/form/move/' + templateId + '/' + groupId,
        method: 'put'
    })
}

//修改状态
export function modifyTemplateStatus(type, templateId) {
    return request({
        url: '/admin/form/status/' + type + '/' + templateId,
        method: 'put'
    })
}

//删除
export function removeTemplate(templateId) {
    return request({
        url: '/admin/form/remove/' + templateId,
        method: 'delete'
    })
}


