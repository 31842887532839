import request from '@/api/request.js'

//查询所有表单分组
export function getFormGroups() {
    return request({
        url: '/admin/form/group/all',
        method: 'get'
    })
}

//添加表单
export function addGroup(data) {
    return request({
        url: '/admin/form/group',
        method: 'post',
        data: data
    })
}

//更新分组
export function updateGroup(data) {
    return request({
        url: '/admin/form/group',
        method: 'put',
        data: data
    })
}

//表单排序
export function groupItemsSort(param) {
    return request({
        url: '/admin/form/group/sort',
        method: 'put',
        data: param
    })
}

//删除表单
export function deleteGroup(groupId) {
    return request({
        url: '/admin/form/group/' + groupId,
        method: 'delete'
    })
}


//修改状态
export function modifyTemplateStatus(type, templateId) {
    return request({
        url: '/admin/form/status/' + type + '/' + templateId,
        method: 'put'
    })
}
